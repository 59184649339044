import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormLocalDateField, FormTextField } from '@eas/common-web';

export function DeadlineDialog() {
  return (
    <>
      <FormLocalDateField
        name="startDate"
        label={
          <FormattedMessage
            id="ES__ES_FILES__DEADLINE_FORM_FIELDS__FIELD_LABEL__START_DATE"
            defaultMessage="Začátek"
          />
        }
      />
      <FormTextField
        type="number"
        name="duration"
        label={
          <FormattedMessage
            id="ES__ES_FILES__DEADLINE_FORM_FIELDS__FIELD_LABEL__DURATION"
            defaultMessage="Počet dnů"
          />
        }
        autoFocus={true}
      />
    </>
  );
}
