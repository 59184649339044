import React from 'react';
import { FormattedMessage } from 'react-intl';
import Divider from '@material-ui/core/Divider/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { DecisionText } from '@composite/decision-text/decision-text';
import { AnonymizedDecisionActions } from '../anonymized-decision-actions';

const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    height: 3,
    margin: '0px 0px 10px',
  },
}));

export function JustificationFields() {
  const classes = useStyles();

  return (
    <>
      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_NARRATION"
            defaultMessage="Narace - plné znění"
          />
        }
        name="text.narration"
        enableHighlighting={true}
      />

      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__TEXT_ARGUMENTATION"
            defaultMessage="Argumentace - plné znění"
          />
        }
        name="text.argumentation"
        enableHighlighting={true}
      />

      <Divider className={classes.divider} />

      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_NARRATION"
            defaultMessage="Narace - anonymizováno"
          />
        }
        name="anonymizedText.narration"
        Before={<AnonymizedDecisionActions fieldName="narration" />}
        enableHighlighting={true}
      />

      <DecisionText
        label={
          <FormattedMessage
            id="ES__DECISION__DETAIL__PANEL_TITLE__ANONYMIZED_TEXT_ARGUMENTATION"
            defaultMessage="Argumentace - anonymizováno"
          />
        }
        name="anonymizedText.argumentation"
        Before={<AnonymizedDecisionActions fieldName="argumentation" />}
        enableHighlighting={true}
      />
    </>
  );
}
