import { DecisionState, Role, UIElement } from '@enums';

export const decisionAccessMap = new Map([
  [
    DecisionState.IN_PROGRESS,
    new Map([
      [
        Role.SECRETARY,
        [
          UIElement.Decision.PUBLISH_IN_COLLECTION,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ANALYST,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.INDEXING,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ANONYMIZATION,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.PUBLICATION,
        [
          UIElement.Decision.FORM,
          UIElement.Decision.DECISION_DATE,
          UIElement.Decision.PUBLICATION_DATE,
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.TEXT,
          UIElement.Decision.FULL_TEXT,
          UIElement.Decision.PUBLIC_TEXT,
          UIElement.Decision.INDEXING,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ADVISER,
        [
          UIElement.Decision.PUBLISH_IN_COLLECTION,
          UIElement.Decision.PARALLEL_CITATION_SB,
          UIElement.Decision.PARALLEL_CITATION_SBNU,
          UIElement.Decision.NOTES,
          UIElement.Decision.CORRECTED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
    ]),
  ],
  [
    DecisionState.INDEXING,
    new Map([
      [
        Role.ANALYST,
        [
          UIElement.Decision.JUDGE,
          UIElement.Decision.FORM,
          UIElement.Decision.SETTLEMENT_METHODS,
          UIElement.Decision.TAKE_OVER_INDEXES,
          UIElement.Decision.DECISION_DATE,
          UIElement.Decision.PUBLICATION_DATE,
          UIElement.Decision.POPULAR_NAME,
          UIElement.Decision.IMPORTANCE,
          UIElement.Decision.DIFFERENT_OPINION,
          UIElement.Decision.NOTE,
          UIElement.Decision.EXCLUDED_FROM_NALUS,
          UIElement.Decision.NOTES,
          UIElement.Decision.TEXT,
          UIElement.Decision.ANONYMIZED_TEXT,
          UIElement.Decision.OTHER_ANNOUNCEMENTS,
          UIElement.Decision.IN_PROGRESS,
          UIElement.Decision.TO_ANONYMIZE,
          UIElement.Decision.TO_MAKE_ACCESSIBLE,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ANONYMIZATION,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.TEXT,
          UIElement.Decision.ANONYMIZED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.PUBLICATION,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ADVISER,
        [
          UIElement.Decision.PUBLISH_IN_COLLECTION,
          UIElement.Decision.PARALLEL_CITATION_SB,
          UIElement.Decision.PARALLEL_CITATION_SBNU,
          UIElement.Decision.NOTES,
          UIElement.Decision.CORRECTED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
    ]),
  ],
  [
    DecisionState.TO_ANONYMIZE,
    new Map([
      [
        Role.ANALYST,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ANONYMIZATION,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.TEXT,
          UIElement.Decision.ANONYMIZED_TEXT,
          UIElement.Decision.INDEXING,
          UIElement.Decision.TO_MAKE_ACCESSIBLE,
          UIElement.Decision.REGENERATE_ATTACHMENTS,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.PUBLICATION,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ADVISER,
        [
          UIElement.Decision.PUBLISH_IN_COLLECTION,
          UIElement.Decision.PARALLEL_CITATION_SB,
          UIElement.Decision.PARALLEL_CITATION_SBNU,
          UIElement.Decision.NOTES,
          UIElement.Decision.CORRECTED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
    ]),
  ],
  [
    DecisionState.TO_MAKE_ACCESSIBLE,
    new Map([
      [
        Role.ANALYST,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ANONYMIZATION,
        [
          UIElement.Decision.NOTE,
          UIElement.Decision.NOTES,
          UIElement.Decision.TEXT,
          UIElement.Decision.ANONYMIZED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.PUBLICATION,
        [
          UIElement.Decision.ALLOW_PUBLISHING,
          UIElement.Decision.DECISION_DATE,
          UIElement.Decision.PUBLICATION_DATE,
          UIElement.Decision.NOTE,
          UIElement.Decision.EXCLUDED_FROM_NALUS,
          UIElement.Decision.NOTES,
          UIElement.Decision.TEXT,
          UIElement.Decision.ANONYMIZED_TEXT,
          UIElement.Decision.OTHER_ANNOUNCEMENTS,
          UIElement.Decision.INDEXING,
          UIElement.Decision.TO_ANONYMIZE,
          UIElement.Decision.ACCESSIBLE,
          UIElement.Decision.REGENERATE_ATTACHMENTS,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
      [
        Role.ADVISER,
        [
          UIElement.Decision.PUBLISH_IN_COLLECTION,
          UIElement.Decision.PARALLEL_CITATION_SB,
          UIElement.Decision.PARALLEL_CITATION_SBNU,
          UIElement.Decision.NOTES,
          UIElement.Decision.CORRECTED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
    ]),
  ],
  [
    DecisionState.ACCESSIBLE,
    new Map([
      [Role.PUBLICATION, [UIElement.Decision.CANCEL_ACCESS]],
      [
        Role.ADVISER,
        [
          UIElement.Decision.PUBLISH_IN_COLLECTION,
          UIElement.Decision.PARALLEL_CITATION_SB,
          UIElement.Decision.PARALLEL_CITATION_SBNU,
          UIElement.Decision.CORRECTED_TEXT,
          UIElement.Decision.EDIT_DECISION_BUTTON,
        ],
      ],
    ]),
  ],
]);
