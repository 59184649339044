import { merge } from 'lodash';
import {
  EmptyComponent,
  EvidenceProps,
  createItemFactory,
  getItemFactory,
  updateItemFactory,
} from '@eas/common-web';
import { useAuthoredEvidence } from '@composite/evidence/authored-evidence';
import {
  createdLockedDocumentStyle,
  isDocumentLocked,
} from '@components/evidence/utils/is-locked';
import { Document } from '@models';
import { EntityName, EvidenceApiUrl, ExportTags } from '@enums';
import { postProcessGetDocument } from './document-api';
import { useDocumentsHookedSources } from './document-hooked-sources';

export function useDocuments<T extends Document>({
  evidenceProps,
}: {
  evidenceProps: Partial<EvidenceProps<T>>;
}) {
  const props = merge(
    {
      identifier: 'DOCUMENTS',
      apiProps: {
        url: EvidenceApiUrl.DOCUMENTS,
        hookedSources: useDocumentsHookedSources,
        getItem: getItemFactory({
          postProcess: postProcessGetDocument,
        }),
        createItem: createItemFactory({
          postProcess: postProcessGetDocument,
        }),
        updateItem: updateItemFactory({
          postProcess: postProcessGetDocument,
        }),
      },
      tableProps: {
        createRowStyle: (item: T) => createdLockedDocumentStyle(item),
        defaultSorts: [],
        showBulkActionButton: false,
        showNamedSettingsButton: false,
        reportTag: ExportTags.DOCUMENTS,
      },
      detailProps: {
        entityName: EntityName.DOCUMENT,
        lock: (item: T) => isDocumentLocked(item),
        GeneralFieldsComponent: EmptyComponent,
        toolbarProps: {
          subtitle: (value: T) => value?.referenceNumber ?? '',
          style: {
            background: 'linear-gradient(to bottom, #FAFAFA, #FFEB3B)',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
            borderBottom: '3px solid #FBC02D',
          },
        },
      },
    },
    evidenceProps
  );

  const evidenceBag = useAuthoredEvidence<T>(props);

  return { evidenceBag };
}
