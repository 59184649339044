import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  PromptDialogContext,
  SnackbarContext,
  useEventCallback,
} from '@eas/common-web';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { Messages } from '@enums';
import { useAddChallengedActDialog } from './add-challenged-act-hook';

export function CopyButton({ loading }: { loading: boolean }) {
  const classes = useStyles();
  const { callApi } = useAddChallengedActDialog();

  const { showSnackbar } = useContext(SnackbarContext);
  const { formRef } = useContext(PromptDialogContext);

  const handleCopy = useEventCallback(async () => {
    const fieldValues = formRef.current?.getFieldValues();
    const errors = await formRef.current?.validateForm();

    if (errors?.length === 0) {
      try {
        await callApi(fieldValues).raw();

        showSnackbar(...Messages.EsFile.COPY_CHALLENGED_ACT.SUCCESS);

        formRef.current?.setFieldValues(fieldValues);
      } catch (err) {
        showSnackbar(...Messages.EsFile.COPY_CHALLENGED_ACT.ERROR);
      }
    }
  });

  return (
    <Button
      type="submit"
      onClick={handleCopy}
      variant="outlined"
      color="primary"
      disabled={loading}
    >
      <Typography classes={{ root: classes.buttonLabel }}>
        <FormattedMessage
          id="ES__CHALLENGED_ACTS__COPY"
          defaultMessage="Kopie"
        />
      </Typography>
    </Button>
  );
}
