import * as Yup from 'yup';
import { Deadline } from '@models';

export function useValidationSchema({ startDateRequired = false } = {}) {
  if (startDateRequired) {
    return Yup.object<Deadline>().shape({
      duration: Yup.number()
        .nullable()
        .required()
        .positive('Musí být kladné číslo'),
      startDate: Yup.date().nullable().required('Povinné pole'),
    });
  } else {
    return Yup.object<Deadline>().shape({
      duration: Yup.number()
        .nullable()
        .required()
        .positive('Musí být kladné číslo'),
    });
  }
}
