import { EsFileState, Role, UIElement } from '@enums';

export const courtFileAccessMap = new Map([
  [
    EsFileState.NEW,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.CourtFile.DELIVERY_DATE,
          UIElement.CourtFile.STATS_DATE,
          UIElement.CourtFile.INCOMING_NUMBER,
          UIElement.CourtFile.TYPE,
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.CourtFile.CREATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.DELETE_COURT_FILE_BUTTON,
          UIElement.CourtFile.UPDATE_COURT_FILE_AGENDA_BUTTON,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.CourtFile.DELIVERY_DATE,
          UIElement.CourtFile.STATS_DATE,
          UIElement.CourtFile.INCOMING_NUMBER,
          UIElement.CourtFile.TYPE,
          UIElement.CourtFile.PROCESSING_TYPE,
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.CourtFile.CREATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.DELETE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_NUMBER_BUTTON,
          UIElement.CourtFile.UPDATE_COURT_FILE_AGENDA_BUTTON,
        ],
      ],
      [Role.INDEX, []],
      [
        Role.LAWYER,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
        ],
      ],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
  [
    EsFileState.REGISTER_NEW,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.CourtFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.CourtFile.PROCESSING_TYPE,
          UIElement.CourtFile.NOTES,
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.CourtFile.PHYSICAL_STORAGE,
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.ACCESSORY_PARTICIPANTS,
          UIElement.CourtFile.LEGAL_ADVISERS,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_ACCEPTANCE_LETTER_BUTTON,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.MERGE_FILES_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
          UIElement.CourtFile.UPDATE_TO_INDEX_BUTTON,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
        ],
      ],
      [
        Role.LAWYER,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.ACCESSORY_PARTICIPANTS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.CourtFile.LEGAL_ADVISERS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.SECRETARY, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.FILE_ROOM, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANONYMIZATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.PUBLICATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ADVISER, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
  [
    EsFileState.INDEX,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.CourtFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.CourtFile.PHYSICAL_STORAGE,
          UIElement.CourtFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_ACCEPTANCE_LETTER_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
        ],
      ],
      [
        Role.LAWYER,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.ACCESSORY_PARTICIPANTS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.CourtFile.LEGAL_ADVISERS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.UPDATE_TO_JUDGE_OFFICE_BUTTON,
          UIElement.CourtFile.UPDATE_TO_REGISTER_NEW_BUTTON,
        ],
      ],
      [Role.SECRETARY, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.FILE_ROOM, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANONYMIZATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.PUBLICATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ADVISER, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
  [
    EsFileState.JUDGE_OFFICE,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.CourtFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.CourtFile.SETTLEMENT_METHODS,
          UIElement.CourtFile.DECISSION_FORM,
          UIElement.CourtFile.DISCUSSION_DATE,
          UIElement.CourtFile.PUBLICATION_DATE,
          UIElement.CourtFile.FEASIBILITY,
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.ACCESSORY_PARTICIPANTS,
          UIElement.CourtFile.LEGAL_ADVISERS,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_ACCEPTANCE_LETTER_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
          UIElement.CourtFile.VIEWINGS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.MERGE_FILES_BUTTON,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
        ],
      ],
      [
        Role.LAWYER,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.SECRETARY,
        [
          UIElement.CourtFile.NOTES,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.DRAFTS,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.UPDATE_TO_INDEX_BUTTON,
          UIElement.CourtFile.UPDATE_TO_REGISTER_BUTTON,
          UIElement.Document.CREATE_VOTING_PROTOCOL_BUTTON,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
        ],
      ],
      [
        Role.JUDGE,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.DRAFTS,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
        ],
      ],
      [
        Role.ASSISTANT,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.DRAFTS,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ANALYST,
        [
          UIElement.CourtFile.PROCESSING_TYPE,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.FILE_ROOM, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.ANONYMIZATION,
        [
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
        ],
      ],
      [
        Role.PUBLICATION,
        [
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ADVISER,
        [
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
  [
    EsFileState.REGISTER,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.CourtFile.NOTES,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.HEAD_OSV,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.INDEX,
        [
          UIElement.CourtFile.PHYSICAL_STORAGE,
          UIElement.CourtFile.SETTLEMENT_METHODS,
          UIElement.CourtFile.DECISSION_FORM,
          UIElement.CourtFile.DISCUSSION_DATE,
          UIElement.CourtFile.PUBLICATION_DATE,
          UIElement.CourtFile.FEASIBILITY,
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.ACCESSORY_PARTICIPANTS,
          UIElement.CourtFile.LEGAL_ADVISERS,
          UIElement.Document.ASSING_DELIVERED_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_INTERNAL_DOCUMENT_BUTTON,
          UIElement.Document.CREATE_OUTGOING_DOCUMENT_BUTTON,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.UPDATE_TO_JUDGE_OFFICE_BUTTON,
          UIElement.CourtFile.UPDATE_TO_CLOSED_BUTTON,
          UIElement.CourtFile.MERGE_FILES_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.VIEWINGS,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
        ],
      ],
      [
        Role.ADMIN,
        [
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.JUDGE,
          UIElement.CourtFile.INDEX,
          UIElement.CourtFile.SECRETARY,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.ASSISTANT,
          UIElement.CourtFile.DECIDING_AUTHORITY_MEMBERS,
        ],
      ],
      [
        Role.LAWYER,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.SECRETARY,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.JUDGE,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ASSISTANT,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ANALYST,
        [
          UIElement.CourtFile.PROCESSING_TYPE,
          UIElement.CourtFile.PROPOSERS,
          UIElement.CourtFile.CHALLENGED_ACTS,
          UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS,
          UIElement.EsFileCommon.SUBJECT_INDEXES,
          UIElement.EsFileCommon.REGULATIONS,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.ANALYST,
          UIElement.CourtFile.DRAFTS,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.FILE_ROOM,
        [
          UIElement.CourtFile.NOTES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ANONYMIZATION,
        [
          UIElement.EsFileCommon.ANONYMIZATION_REQUEST,
          UIElement.EsFileCommon.ANONYMIZE,
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.UPDATE_COURT_FILE_BUTTON,
        ],
      ],
      [
        Role.PUBLICATION,
        [
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [
        Role.ADVISER,
        [
          UIElement.CourtFile.TASKS_AND_DEADLINES,
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
        ],
      ],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
  [
    EsFileState.CLOSED,
    new Map([
      [
        Role.LUSTRUM,
        [
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.REOPEN_COURT_FILE_BUTTON,
        ],
      ],
      [Role.HEAD_OSV, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.INDEX,
        [
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.REOPEN_COURT_FILE_BUTTON,
        ],
      ],
      [Role.LAWYER, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.SECRETARY, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.FILE_ROOM, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANONYMIZATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.PUBLICATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ADVISER, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
  [
    EsFileState.REGISTRY,
    new Map([
      [Role.LUSTRUM, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.HEAD_OSV, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.INDEX, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.LAWYER, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.SECRETARY, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.JUDGE, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ASSISTANT, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ANALYST, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [
        Role.FILE_ROOM,
        [
          UIElement.CourtFile.CREATE_FILE_COVER_BUTTON,
          UIElement.CourtFile.VIEWINGS,
        ],
      ],
      [Role.ANONYMIZATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.PUBLICATION, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.ADVISER, [UIElement.CourtFile.CREATE_FILE_COVER_BUTTON]],
      [Role.VIEWING_REQUEST_MANAGER, [UIElement.CourtFile.VIEWINGS]],
    ]),
  ],
]);
