import React, {useContext} from 'react';
import {DetailContext, DetailHandle, PrimaryDetailActionbarButton,} from '@eas/common-web';
import {ViewingRequest} from '@models';
import {ViewingRequestState} from '@enums';
import {PrintPhysicalDetailsButton} from './dialog-actions/print-physical-details-button';
import {VerifyDataBoxButton} from './dialog-actions/verify-data-box-button';

export function ActionBar() {
  const { source } = useContext<DetailHandle<ViewingRequest>>(DetailContext);

  const dataBoxId = source?.data?.requester?.contact?.dataBox?.identifier;
  const viewingMethod = source?.data?.viewingMethod;
  const state = source?.data?.state;
  const physicalViewing = source?.data?.physicalViewing;

  return (
    <>
      {dataBoxId && (
        <VerifyDataBoxButton
          dataBoxId={dataBoxId}
          ButtonComponent={PrimaryDetailActionbarButton}
        />
      )}

      {viewingMethod === 'PHYSICAL' &&
        state === ViewingRequestState.PROCESSING &&
        physicalViewing && (
          <PrintPhysicalDetailsButton />
        )}
    </>
  );
}
