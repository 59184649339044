import clsx from 'clsx';
import { stubFalse } from 'lodash';
import React, { useContext } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import { green, orange, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import {
  DetailContext,
  FormCustomField,
  FormFieldContext,
  FormFileField,
  FormLocalDateTimeField,
  FormPanel,
  FormSelect,
  FormTableField,
  FormTextField,
  TableFieldCells,
  TextField,
  useFormSelector,
  useStaticListSource,
} from '@eas/common-web';
import { useDeliveredDocumentPermission } from '@modules/document/document-permission';
import { EsFileFields } from '@modules/document/fields/es-file/es-file';
import { useDownloadValidationReportDialog } from '@composite/attachments/dialog-actions/download-validation-report-hook';
import { useRevalidateAttachmentDialog } from '@composite/attachments/dialog-actions/revalidate-attachment-hook';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { useStyles as useTableStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { DeliveredDocument, DocumentAttachment } from '@models';
import {
  DeliveredDocumentType,
  EvidenceApiUrl,
  SignatureInfoCertificateType,
  SignatureInfoEidasType,
  SignatureInfoStatus,
  UIElement,
} from '@enums';

const useStyles = makeStyles({
  valid: {
    color: green[500],
  },
  invalid: {
    color: red[500],
  },
  warning: {
    color: orange[500],
  },
  lineHeight: {
    lineHeight: 'unset',
  },
});

export function Validations() {
  const classes = useStyles();

  const {
    validationStatuses,
    validationCertificationTypes,
    validationEidasTypes,
  } = useContext(StaticDataContext);

  const {
    id,
    valid,
    pdfReportId,
    eidasType,
    status,
    certificateType,
    deliveryMethod,
    processed,
  } = useFormSelector((data: DeliveredDocument) => ({
    id: data.id,
    valid: data.validation?.valid,
    pdfReportId: data.validation?.pdfReportId,
    eidasType: data.validation?.info?.eidasType,
    certificateType: data.validation?.info?.certificateType,
    status: data.validation?.info?.status,
    deliveryMethod: data.deliveryMethod,
    processed: data.processed,
  }));

  const certificationTypeSource = useStaticListSource(
    validationCertificationTypes
  );

  const { classes: resultClass } = useResultClasses(
    eidasType,
    certificateType,
    status
  );

  return (
    <>
      {processed && <EsFileFields />}
      {deliveryMethod !== DeliveredDocumentType.LETTER && (
        <>
          <FormPanel label="Prvotní validace elektronické zprávy">
            <FormCustomField label="Elektronická zpráva je validní">
              {valid === true && <CheckIcon className={classes.valid} />}
              {valid === false && <CloseIcon className={classes.invalid} />}
              {(valid === undefined || valid === null) && (
                <TextField
                  value="Dokument nemá přílohy a není tedy validován"
                  disabled
                />
              )}
            </FormCustomField>
            <FormLocalDateTimeField
              name="validation.validationTime"
              label="Datum a čas validace"
            />
            {pdfReportId && (
              <FormFileField
                name="validation"
                label="Report"
                customDownloadUrl={`${EvidenceApiUrl.DOCUMENTS}/${id}/report`}
              />
            )}
          </FormPanel>
          <FormPanel label="Detaily podpisů">
            <FormTextField
              name="validation.info.certificateName"
              label="Název certifikátu"
              disabled
            />
            <FormLocalDateTimeField
              name="validation.info.signTime"
              label="Datum a čas podpisu"
              disabled
            />
            <FormLocalDateTimeField
              name="validation.info.validationTime"
              label="Datum a čas validace"
              disabled
            />
            <FormSelect
              source={certificationTypeSource}
              name="validation.info.certificateType"
              label="Typ certifikátu"
              valueIsId
              disabled
            />
            <FormCustomField
              name="validation.info.eidasType"
              label="Úroveň podpisu"
            >
              <Typography className={resultClass} variant="body1">
                {validationEidasTypes.find((t) => t.id === eidasType)?.name}
              </Typography>
            </FormCustomField>
            <FormCustomField
              name="validation.info.status"
              label="Výsledek validace"
            >
              <Typography className={resultClass} variant="body1">
                {validationStatuses.find((t) => t.id === status)?.name}
              </Typography>
            </FormCustomField>
          </FormPanel>
        </>
      )}
      <AttachmentValidationField />
    </>
  );
}

const AttachmentValidationField = () => {
  const tableClasses = useTableStyles();

  const {
    validationStatuses,
    validationCertificationTypes,
    validationEidasTypes,
  } = useContext(StaticDataContext);
  const { isLocked, source } = useContext(DetailContext);
  const { hasPermission } = useDeliveredDocumentPermission(source.data);

  const useCertificationTypes = () =>
    useStaticListSource(validationCertificationTypes);
  const useStatuses = () => useStaticListSource(validationStatuses);
  const useEidasTypes = () => useStaticListSource(validationEidasTypes);

  const { callApi: revalidateAttachmentApiCall, RevalidateButton } =
    useRevalidateAttachmentDialog();
  const { callApi: downloadReportApiCall, DownloadValidationReportButton } =
    useDownloadValidationReportDialog();

  const { attachments } = useFormSelector((data: DeliveredDocument) => ({
    attachments: data.attachments,
  }));

  return (
    <FormPanel label="Prvotní validace příloh">
      <FormFieldContext.Provider value={{ disabled: false }}>
        <FormTableField
          name="attachments"
          ToolbarComponent={({ selectedIndex }) => (
            <div className={tableClasses.tableActions}>
              <div />
              <ButtonGroup
                size="small"
                variant="outlined"
                className={tableClasses.buttonGroup}
              >
                <RevalidateButton
                  disabled={
                    selectedIndex === undefined ||
                    !attachments![selectedIndex!].file ||
                    !hasPermission(
                      UIElement.Attachment.REVALIDATE_ATTACHMENT
                    ) ||
                    isLocked
                  }
                  apiCall={async () =>
                    await revalidateAttachmentApiCall(
                      attachments![selectedIndex!].id
                    )
                  }
                />
                <DownloadValidationReportButton
                  apiCall={async () =>
                    await downloadReportApiCall(attachments![selectedIndex!].id)
                  }
                  disabled={
                    selectedIndex === undefined ||
                    !attachments![selectedIndex!].validation?.pdfReportId ||
                    !hasPermission(
                      UIElement.Attachment.DOWNLOAD_VALIDATION_REPORT
                    ) ||
                    isLocked
                  }
                />
              </ButtonGroup>
            </div>
          )}
          showDetailBtnCond={stubFalse}
          labelOptions={{ hide: true }}
          layoutOptions={{ noSpacing: true }}
          createRowStyle={function useStyles(row: DocumentAttachment) {
            const { styles } = useResultClasses(
              row.validation?.info?.eidasType,
              row.validation?.info?.certificateType,
              row.validation?.info?.status
            );

            return styles;
          }}
          columns={[
            {
              datakey: 'label',
              name: 'Označení',
              width: 100,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'name',
              name: 'Název přílohy',
              width: 250,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'validation.info.certificateName',
              name: 'Název certifikátu',
              width: 250,
              CellComponent: TableFieldCells.TextCell,
            },
            {
              datakey: 'validation.info.signTime',
              name: 'Datum a čas podpisu',
              width: 250,
              CellComponent: TableFieldCells.DateTimeCell,
            },
            {
              datakey: 'validation.info.validationTime',
              name: 'Datum a čas validace',
              width: 250,
              CellComponent: TableFieldCells.DateTimeCell,
            },
            {
              datakey: 'validation.info.certificateType',
              name: 'Typ certifikátu',
              width: 250,
              CellComponent: TableFieldCells.useSelectCellFactory(
                useCertificationTypes
              ),
            },
            {
              datakey: 'validation.info.eidasType',
              name: 'Úroveň podpisu',
              width: 250,
              CellComponent:
                TableFieldCells.useSelectCellFactory(useEidasTypes),
            },
            {
              datakey: 'validation.info.status',
              name: 'Výsledek validace',
              width: 250,
              CellComponent: TableFieldCells.useSelectCellFactory(useStatuses),
            },
          ]}
        />
      </FormFieldContext.Provider>
    </FormPanel>
  );
};

const useResultClasses = (
  eidasType?: SignatureInfoEidasType,
  certificateType?: SignatureInfoCertificateType,
  status?: SignatureInfoStatus
) => {
  const classes = useStyles();

  if (status !== SignatureInfoStatus.VALID) {
    return {
      classes: clsx(classes.invalid, classes.lineHeight),
      styles: {
        color: red[500],
        backgroundColor: red[50],
      },
    };
  }

  if (
    eidasType &&
    [
      SignatureInfoEidasType.ADVANCED_SIGNATURE,
      SignatureInfoEidasType.ADVANCED_SEAL,
    ].includes(eidasType) &&
    certificateType &&
    [
      SignatureInfoCertificateType.UNKNOWN,
      SignatureInfoCertificateType.INTERNAL_STORAGE,
    ].includes(certificateType)
  ) {
    return {
      classes: clsx(classes.invalid, classes.lineHeight),
      styles: {
        color: red[500],
        backgroundColor: red[50],
      },
    };
  }

  if (
    eidasType &&
    [
      SignatureInfoEidasType.ADVANCED_SIGNATURE,
      SignatureInfoEidasType.ADVANCED_SEAL,
    ].includes(eidasType) &&
    certificateType === SignatureInfoCertificateType.COMMERCIAL
  ) {
    return {
      classes: clsx(classes.warning, classes.lineHeight),
      styles: {
        color: orange[500],
        backgroundColor: orange[50],
      },
    };
  }

  if (
    eidasType &&
    [
      SignatureInfoEidasType.ACREDITED_SIGNATURE,
      SignatureInfoEidasType.ACREDITED_SEAL,
      SignatureInfoEidasType.TRUSTED_SEAL,
      SignatureInfoEidasType.QUALIFIED_SIGNATURE,
      SignatureInfoEidasType.QUALIFIED_SEAL,
    ].includes(eidasType) &&
    certificateType === SignatureInfoCertificateType.QUALIFIED
  ) {
    return {
      classes: clsx(classes.valid, classes.lineHeight),
      styles: {
        color: green[500],
        backgroundColor: green[50],
      },
    };
  }

  return { classes: classes.lineHeight, styles: {} };
};
