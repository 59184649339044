import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DetailContext,
  DetailHandle,
  DetailMode,
  EvidenceContext,
  FormPanel,
  FormTree,
} from '@eas/common-web';
import { useProceedingsSubjectsTree } from '@modules/dict-proceedings-subject/dict-proceedings-subject-api';
import { useSubjectIndexesTree } from '@modules/dict-subject-index/dict-subject-index-api';
import { REGULATION_SOURCE } from '@modules/es-file/es-file-hooked-sources';
import { RegulationField } from '@composite/regulations/regulation-field';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { TreeDisabledComponent } from '@components/form/tree/tree-disabled-component';
import { EsFile } from '@models';
import { UIElement } from '@enums';

export function IndexesFields({
  hasPermission,
}: {
  hasPermission: (element: string) => boolean;
}) {
  const { hookedSources } = useContext(EvidenceContext);
  const { isLocked, mode } = useContext<DetailHandle<EsFile>>(DetailContext);

  const { regulationTypes, regulationEntityTypes } =
    useContext(StaticDataContext);

  const subjectIndexes = useSubjectIndexesTree();
  const proceedingsSubjects = useProceedingsSubjectsTree();

  const disabledRegulation =
    isLocked ||
    mode !== DetailMode.VIEW ||
    !hasPermission(UIElement.EsFileCommon.REGULATIONS);
  const regulationSource = hookedSources[REGULATION_SOURCE]?.source;

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__PANEL_TITLE__PROCEEDINGS_SUBJECTS"
            defaultMessage="Předmět řízení"
          />
        }
      >
        <FormTree
          name="proceedingsSubjects"
          label={
            <FormattedMessage
              id="ES__ES_FILES__DETAIL__FIELD_LABEL__PROCEEDINGS_SUBJECTS"
              defaultMessage="Předmět řízení"
            />
          }
          labelOptions={{
            hide: true,
          }}
          source={proceedingsSubjects}
          disabled={!hasPermission(UIElement.EsFileCommon.PROCEEDINGS_SUBJECTS)}
          DisabledComponent={TreeDisabledComponent}
        />
      </FormPanel>

      <FormPanel
        label={
          <FormattedMessage
            id="ES__ES_FILES__DETAIL__PANEL_TITLE__SUBJECT_INDEXES"
            defaultMessage="Věcný rejstřík"
          />
        }
      >
        <FormTree
          name="subjectIndexes"
          label={
            <FormattedMessage
              id="ES__ES_FILES__DETAIL__FIELD_LABEL__SUBJECT_INDEXES"
              defaultMessage="Věcný rejstřík"
            />
          }
          labelOptions={{
            hide: true,
          }}
          source={subjectIndexes}
          disabled={!hasPermission(UIElement.EsFileCommon.SUBJECT_INDEXES)}
          DisabledComponent={TreeDisabledComponent}
        />
      </FormPanel>

      <RegulationField
        regulationTypes={regulationTypes}
        regulationEntityTypes={regulationEntityTypes}
        disabled={disabledRegulation}
        source={regulationSource}
      />
    </>
  );
}
