import { format } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormAutocomplete,
  FormCheckbox,
  FormDateTimeField,
  FormPanel,
  TextField,
  TextFieldProps,
  formFieldFactory,
} from '@eas/common-web';
import { HistoryField } from '@composite/history/history-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { useJudges } from '../judge/judge-api';
import { useHistoryDescriptors } from './assigning-rule-history-descriptors';

const useStyles = makeStyles({
  smallTextField: {
    display: 'flex',
    width: '100%',
  },
  smallTextInput: { lineHeight: '22px', fontSize: 12 },
});

export function Fields() {
  const judges = useJudges();
  const historyDescriptors = useHistoryDescriptors();
  const intl = useIntl();

  console.log('judges', judges);

  return (
    <>
      <FormPanel
        label={intl.formatMessage({
          id: 'ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__GENERAL',
          defaultMessage: 'Obecné',
        })}
        expandable={false}
      >
        <FormAutocomplete
          name="judge"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__JUDGE',
            defaultMessage: 'Soudce',
          })}
          source={judges}
          labelMapper={autocompleteLabelMapper}
        />
      </FormPanel>
      <AssigningRulesFields />
      <HistoryField descriptors={historyDescriptors} />
    </>
  );
}

const MiniTextField = ({
  help,
  ...props
}: TextFieldProps & { help: string }) => {
  const classes = useStyles();

  return (
    <div className={classes.smallTextField}>
      <TextField
        {...props}
        style={{ width: 100, flexShrink: 0, marginRight: 15 }}
      />
      <Typography variant="body1" className={classes.smallTextInput}>
        {help}
      </Typography>
    </div>
  );
};

const FormMiniTextField = formFieldFactory(MiniTextField);

export function AssigningRulesFields() {
  const validateNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[^0-9]/gi, '');
  };

  const intl = useIntl();
  const currentDate = format(new Date(), 'yyyy-MM-dd');

  return (
    <>
      <FormPanel
        label={intl.formatMessage({
          id: 'ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__SENATE',
          defaultMessage: 'Senátní',
        })}
        expandable={false}
      >
        <FormCheckbox
          name="stopSenate"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__STOP_SENATE',
            defaultMessage: 'Pozastavit',
          })}
        />
        <FormMiniTextField
          name="assignXSenate"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_SENATE',
            defaultMessage: 'Přidělit x',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_SENATE',
            defaultMessage:
              'Přidělení x senátních věcí a pozastavení procesu přidělování',
          })}
          onChangeEvent={validateNumber}
          inputProps={{ maxLength: 5 }}
        />
        <FormMiniTextField
          name="assignXSenateCounter"
          type="number"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_SENATE_COUNTER',
            defaultMessage: 'Přiděleno celkově',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_SENATE_COUNTER',
            defaultMessage:
              'Počítadlo v kolika kolech již byla přidělena senátní věc (po přidělení se zvýší o 1)',
          })}
          disabled
        />
        <FormMiniTextField
          name="skipEveryXSenate"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_SENATE',
            defaultMessage: 'Vynechat každé x - té',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_SENATE',
            defaultMessage:
              'Vynechání každého xtého kola přidělování senátních věcí',
          })}
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="skipEveryXSenateCounter"
          type="number"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_SENATE_COUNTER',
            defaultMessage: 'Přiděleno v sekvenci',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_SENATE_COUNTER',
            defaultMessage:
              'Počítadlo v kolika kolech již byla přidělena senátní věc (po přidělení se zvýší o 1, při přeskočení se nastaví na 0)',
          })}
          disabled
        />
        <FormMiniTextField
          name="skipXSenate"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_X_SENATE',
            defaultMessage: 'Přeskočit x',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_X_SENATE',
            defaultMessage:
              'Kolik následujících kol přidělování senátních věcí má být přeskočeno. Po přeskočení se sníží o 1',
          })}
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
      </FormPanel>

      <FormPanel
        label={intl.formatMessage({
          id: 'ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__PLENARY',
          defaultMessage: 'Plenární',
        })}
        expandable={false}
      >
        <FormCheckbox
          name="stopPlenary"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__STOP_PLENARY',
            defaultMessage: 'Pozastavit',
          })}
        />

        <FormMiniTextField
          name="assignXPlenary"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_PLENARY',
            defaultMessage: 'Přidělit x',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_PLENARY',
            defaultMessage:
              'Přidělení x plenárních věcí a pozastavení procesu přidělování',
          })}
          inputProps={{ max: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="assignXPlenaryCounter"
          type="number"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__ASSIGN_X_PLENARY_COUNTER',
            defaultMessage: 'Přiděleno celkově',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__ASSIGN_X_PLENARY_COUNTER',
            defaultMessage:
              'Počítadlo v kolika kolech již byla přidělena plenární věc (po přidělení se zvýší o 1)',
          })}
          disabled
        />
        <FormMiniTextField
          name="skipEveryXPlenary"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_PLENARY',
            defaultMessage: 'Vynechat každé x - té',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_PLENARY',
            defaultMessage:
              'Vynechání každého xtého kola přidělování plenárních věcí',
          })}
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
        <FormMiniTextField
          name="skipEveryXPlenaryCounter"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_EVERY_X_PLENARY_COUNTER',
            defaultMessage: 'Přiděleno v sekvenci',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_EVERY_X_PLENARY_COUNTER',
            defaultMessage:
              'Počítadlo v kolika kolech již byla přidělena plenární věc (po přidělení se zvýší o 1, při přeskočení se nastaví na 0)',
          })}
          disabled
        />
        <FormMiniTextField
          name="skipXPlenary"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__SKIP_X_PLENARY',
            defaultMessage: 'Přeskočit x',
          })}
          help={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_HELP__SKIP_X_PLENARY',
            defaultMessage:
              'Kolik následujících kol přidělování plernárních věcí má být přeskočeno. Po přeskočení se sníží o 1',
          })}
          inputProps={{ maxLength: 5 }}
          onChangeEvent={validateNumber}
        />
      </FormPanel>

      <FormPanel
        label={intl.formatMessage({
          id: 'ES__ASSIGNING_RULES__DETAIL__PANEL_TITLE__VALID',
          defaultMessage: 'Platnost',
        })}
        expandable={false}
      >
        <FormDateTimeField
          name="validFrom"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__VALID_FROM',
            defaultMessage: 'Platný od',
          })}
          required
          representation="local-date-time"
          minDatePicker={currentDate}
        />
        <FormDateTimeField
          name="validTo"
          label={intl.formatMessage({
            id: 'ES__ASSIGNING_RULES__DETAIL__FIELD_LABEL__VALID_TO',
            defaultMessage: 'Platný do',
          })}
          representation="local-date-time"
        />
      </FormPanel>
    </>
  );
}
