import React, {
  ReactElement,
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';
import ButtonGroup from '@material-ui/core/ButtonGroup/ButtonGroup';
import Typography from '@material-ui/core/Typography';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {
  ActionButtonHandle,
  ApiFilterOperation,
  DetailContext,
  DetailMode,
  DictionaryObject,
  EmptyComponent,
  FieldFilter,
  Filter,
  RemoteTableFieldContext,
  SnackbarContext,
  TableFieldToolbarButton,
  TableFieldToolbarButtons,
  TableFieldToolbarHandle,
  TableFieldToolbarProps,
  useStaticListSource,
} from '@eas/common-web';
import { DetailActionButton } from '@components/action-button/detail-action-button';
import { useStyles } from '@components/form/table-field/form-table-toolbar-styles';
import { ErrorObject } from '@models';
import { DeadlineState, Messages } from '@enums';
import { DeadlineContext } from './deadline-context';
import { DeadlineDialog } from './deadline-dialog';
import { useValidationSchema } from './deadline-schema';
import { useAddDeadlineDialog } from './dialog-actions/add-deadline-hook';
import { useEditDeadlineDialog } from './dialog-actions/edit-deadline-hook';

export const DeadlineToolbar = forwardRef(function DeadlineToolbar(
  {
    selectedIndex,
    setSelectedIndex,
    disabled,
    preFilters,
    deadlineStates,
  }: TableFieldToolbarProps & {
    disabled: boolean;
    preFilters: Filter[];
    deadlineStates: DictionaryObject[];
  },
  ref: Ref<TableFieldToolbarHandle>
) {
  const classes = useStyles();
  const intl = useIntl();

  const { display, filtersFields } = useContext(DeadlineContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { source: detailSource } = useContext(DetailContext);
  const { source: tableSource } = useContext(RemoteTableFieldContext);

  const deadlines = tableSource.items;
  const hasDeliveryDate = !!detailSource?.data?.deliveryDate;

  const validationSchema = useValidationSchema({
    startDateRequired: display === 'IN_DOCUMENT' && !hasDeliveryDate,
  });

  const { callApi: addDeadlineApiCall } = useAddDeadlineDialog();
  const { callApi: editDeadlineApiCall } = useEditDeadlineDialog();

  useEffect(() => {
    setSelectedIndex(undefined);
  }, [detailSource]);

  const editRef = useRef<ActionButtonHandle>(null);
  const metRef = useRef<ActionButtonHandle>(null);
  const notMetRef = useRef<ActionButtonHandle>(null);
  const cancelRef = useRef<ActionButtonHandle>(null);

  useImperativeHandle(
    ref,
    () => ({
      executeAction: (action) => {
        switch (action) {
          case 'EDIT':
            editRef.current?.executeAction();
            break;
          case 'MET':
            metRef.current?.executeAction();
            break;
          case 'NOT_MET':
            notMetRef.current?.executeAction();
            break;
          case 'CANCEL':
            cancelRef.current?.executeAction();
            break;

          default:
            break;
        }
      },
    }),
    []
  );

  const deafultFiltersFields = useFiltersFields(deadlineStates);

  const selectedDeadline =
    selectedIndex !== undefined ? deadlines[selectedIndex] : {};

  return (
    <div className={classes.tableActions}>
      <ButtonGroup className={classes.buttonGroup}>
        {display === 'IN_DOCUMENT' && (
          <DetailActionButton
            promptKey="ADD_DEADLINE"
            apiCall={addDeadlineApiCall}
            buttonLabel={intl.formatMessage({
              id: 'ES__DEADLINES_TOOLBAR__ADD_DEADLINE',
              defaultMessage: 'Přidat lhůtu',
            })}
            dialogTitle={intl.formatMessage({
              id: 'ES__DEADLINES_TOOLBAR__ADD_DEADLINE__TITLE',
              defaultMessage: 'Lhůta',
            })}
            dialogText={intl.formatMessage({
              id: 'ES__DEADLINES_TOOLBAR__ADD_DEADLINE__TEXT',
              defaultMessage: 'Vyplňte začátek a počet dnů.',
            })}
            ButtonComponent={({ label, onClick }) => (
              <TableFieldToolbarButton
                IconComponent={ControlPointIcon}
                show={true}
                disabled={disabled ?? false}
                title={label}
                onClick={onClick}
                color="primary"
                variant="contained"
              >
                <Typography variant="body2">
                  {intl.formatMessage({
                    id: 'ES__DEADLINES_TOOLBAR__ADD_DEADLINE__BUTTON',
                    defaultMessage: 'Přidat',
                  })}
                </Typography>
              </TableFieldToolbarButton>
            )}
            FormFields={DeadlineDialog}
            modes={[DetailMode.VIEW, DetailMode.EDIT]}
            formValidationSchema={validationSchema}
            onError={async (
              err: ErrorObject<'INSUFFICIENT_DAYS_REMAINING'>
            ) => {
              if (err.code) {
                const error =
                  Messages.Deadline.SAVE?.[err.code] ??
                  Messages.Deadline.SAVE.ERROR;
                showSnackbar(...error);
              }
            }}
          />
        )}
        <DetailActionButton
          ref={editRef}
          promptKey="EDIT_DEADLINE"
          apiCall={(id, formData) => editDeadlineApiCall(id, formData)}
          buttonLabel={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__EDIT_DEADLINE',
            defaultMessage: 'Editovat lhůtu',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__EDIT_DEADLINE__TITLE',
            defaultMessage: 'Lhůta',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__EDIT_DEADLINE__TEXT',
            defaultMessage: 'Vyplňte nový začátek nebo počet dnů.',
          })}
          FormFields={DeadlineDialog}
          formInitialValues={selectedDeadline}
          modes={[]}
          formValidationSchema={validationSchema}
          onError={async (err: ErrorObject<'INSUFFICIENT_DAYS_REMAINING'>) => {
            if (err.code) {
              const error =
                Messages.Deadline.SAVE?.[err.code] ??
                Messages.Deadline.SAVE.ERROR;
              showSnackbar(...error);
            }
          }}
        />
        <DetailActionButton
          ref={metRef}
          promptKey="MET_DEADLINE"
          apiCall={(id, formData) => {
            return editDeadlineApiCall(id, {
              ...formData,
              state: DeadlineState.MET,
            });
          }}
          buttonLabel={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__MET_DEADLINE',
            defaultMessage: 'Změnit stav lhůty na Dodržena',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__MET_DEADLINE__TITLE',
            defaultMessage: 'Lhůta',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__MET_DEADLINE__TEXT',
            defaultMessage: 'Skutečně chcete změnit stav lhůty na DODRŽENA?',
          })}
          FormFields={EmptyComponent}
          formInitialValues={selectedDeadline}
          modes={[]}
        />
        <DetailActionButton
          ref={notMetRef}
          promptKey="NOT_MET_DEADLINE"
          apiCall={(id, formData) =>
            editDeadlineApiCall(id, {
              ...formData,
              state: DeadlineState.NOT_MET,
            })
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__NOT_MET_DEADLINE',
            defaultMessage: 'Změnit stav lhůty na Nedodržena',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__NOT_MET_DEADLINE__TITLE',
            defaultMessage: 'Lhůta',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__NOT_MET_DEADLINE__TEXT',
            defaultMessage: 'Skutečně chcete změnit stav lhůty na NEDODRŽENA?',
          })}
          FormFields={EmptyComponent}
          formInitialValues={selectedDeadline}
          modes={[]}
        />
        <DetailActionButton
          ref={cancelRef}
          promptKey="CANCEL_DEADLINE"
          apiCall={(id, formData) =>
            editDeadlineApiCall(id, {
              ...formData,
              state: DeadlineState.CANCELED,
            })
          }
          buttonLabel={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__CANCEL_DEADLINE',
            defaultMessage: 'Změnit stav lhůty na Stornována',
          })}
          dialogTitle={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__CANCEL_DEADLINE__TITLE',
            defaultMessage: 'Lhůta',
          })}
          dialogText={intl.formatMessage({
            id: 'ES__DEADLINES_TOOLBAR__CANCEL_DEADLINE__TEXT',
            defaultMessage: 'Skutečně chcete změnit stav lhůty na STORNOVÁNA?',
          })}
          FormFields={EmptyComponent}
          formInitialValues={selectedDeadline}
          modes={[]}
        />
      </ButtonGroup>
      <ButtonGroup className={classes.buttonGroup}>
        <TableFieldToolbarButtons.FilterButton
          color="default"
          variant="text"
          source={tableSource}
          preFilters={preFilters}
          filtersFields={filtersFields ?? deafultFiltersFields}
        />
      </ButtonGroup>
    </div>
  );
}) as (
  p: TableFieldToolbarProps & {
    ref: Ref<TableFieldToolbarHandle>;
    disabled: boolean;
    preFilters: Filter[];
    deadlineStates: DictionaryObject[];
  }
) => ReactElement;

function useFiltersFields(deadlineStates: DictionaryObject[]) {
  const { display } = useContext(DeadlineContext);

  const intl = useIntl();

  const useDeadlineStates = () => useStaticListSource(deadlineStates ?? []);

  const filtersFields = [
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__STATE',
        defaultMessage: 'Stav',
      }),
      datakey: 'state',
      filterkey: 'state.id',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterSelectCell(useDeadlineStates),
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__STARTING_FROM',
        defaultMessage: 'Počáteční datum',
      }),
      datakey: 'startingFrom',
      filterkey: 'startingFrom',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__END_DATE',
        defaultMessage: 'Konečné datum',
      }),
      datakey: 'endDate',
      filterkey: 'endDate',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterDateCell,
    },
    {
      label: intl.formatMessage({
        id: 'ES__DEADLINES_TOOLBAR__FILTER__DAYS_REMAINING',
        defaultMessage: 'Zbývá',
      }),
      datakey: 'daysRemaining',
      filterkey: 'daysRemaining',
      defaultOperation: ApiFilterOperation.EQ,
      FilterComponent: FieldFilter.FilterNumberCell,
    },
  ];

  if (display === 'IN_ES_FILE') {
    filtersFields.push(
      {
        label: intl.formatMessage({
          id: 'ES__DEADLINES_TOOLBAR__FILTER__REFERENCE_NUMBER',
          defaultMessage: 'Číslo jednací',
        }),
        datakey: 'document.referenceNumber',
        filterkey: 'document.referenceNumber',
        defaultOperation: ApiFilterOperation.EQ,
        FilterComponent: FieldFilter.FilterTextCell,
      },
      {
        label: intl.formatMessage({
          id: 'ES__DEADLINES_TOOLBAR__FILTER__DESCRIPTION',
          defaultMessage: 'Popis',
        }),
        datakey: 'description',
        filterkey: 'description',
        defaultOperation: ApiFilterOperation.EQ,
        FilterComponent: FieldFilter.FilterTextCell,
      },
      {
        label: intl.formatMessage({
          id: 'ES__DEADLINES_TOOLBAR__FILTER__DURATION',
          defaultMessage: 'Počet dnů',
        }),
        datakey: 'duration',
        filterkey: 'duration',
        defaultOperation: ApiFilterOperation.EQ,
        FilterComponent: FieldFilter.FilterNumberCell,
      }
    );
  }

  return filtersFields;
}
