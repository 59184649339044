import { useContext } from 'react';
import { UserContext, useEventCallback } from '@eas/common-web';
import { Decision, Me } from '@models';
import { Role } from '@enums';
import { decisionAccessMap } from './decision-access-map';

export const DECISIONS_ACCESS = [
  Role.LUSTRUM, // Lustrum
  Role.HEAD_OSV, // Vedoucí OSV
  Role.INDEX, // Rejstřík
  Role.LAWYER, // Právník
  Role.SECRETARY, // Tajemnice
  Role.JUDGE, // Soudce
  Role.ASSISTANT, // Asistent
  Role.FILE_ROOM, // Spisovna
  Role.ANALYST, // Analytik
  Role.ANONYMIZATION, // Anonymizace
  Role.PUBLICATION, // Zveřejnění
  Role.READER, // Čtenář
  Role.ADVISER, // Poradce - sbírky
];

export function useDecisionPermission(decision: Decision | null) {
  const { user } = useContext<UserContext<Me>>(UserContext);

  const hasPermission = useEventCallback((element: string) => {
    if (!decision || !decision.state) {
      return false;
    }

    const permittedElements = new Set<string>();
    const statePermissions = decisionAccessMap?.get(decision.state);

    // flat permitted elements into Set
    user?.userRoles.forEach(({ role }) => {
      const permittedForRole = statePermissions?.get(role!) ?? [];

      // if (
      //   role === Role.ANALYST &&
      //   (decision?.esFile as CourtFile)?.analystUserRole?.user?.id !== user?.id
      // ) {
      //   return;
      // }

      permittedForRole?.forEach((element) => permittedElements.add(element));
    });

    return permittedElements.has(element);
  });

  return { hasPermission };
}
