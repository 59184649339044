import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import {
  DictionaryAutocomplete,
  ExportContext,
  FormDateField,
  FormSelect,
  abortableFetch,
  useListSource,
} from '@eas/common-web';
import { EvidenceApiUrl } from '@enums';

export const callApi = (
  dispatchId: string,
  formData: {
    noticeDate: string;
    postingDate: string;
    expirationDate: string;
    exportTemplate: string;
  }
) => {
  return abortableFetch(
    `${EvidenceApiUrl.DOCUMENTS_DISPATCHES}/${dispatchId}/notice-board?templateId=${formData.exportTemplate}`,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify({
        noticeDate: formData.noticeDate,
        postingDate: formData.postingDate,
        expirationDate: formData.expirationDate,
      }),
    }
  );
};

export function useSendByNoticeBoardValidationSchema() {
  return Yup.object().shape({
    noticeDate: Yup.string().nullable().required(),
    postingDate: Yup.string().nullable().required(),
    expirationDate: Yup.string().nullable().required(),
    exportTemplate: Yup.string().nullable().required(),
  });
}

export function SendByNoticeBoardDialog() {
  const { url } = useContext(ExportContext);

  const templates = useListSource<DictionaryAutocomplete>({
    url: `${url}/templates/tagged/CREATE_NOTICE_BOARD_DISPATCH`,
  });

  return (
    <>
      <FormDateField
        name="noticeDate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__NOTICE_DATE"
            defaultMessage="Datum oznámení"
          />
        }
        autoFocus={true}
      />
      <FormDateField
        name="postingDate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__POSTING_DATE"
            defaultMessage="Datum vyvěšení"
          />
        }
      />
      <FormDateField
        name="expirationDate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__EXPIRATION_DATE"
            defaultMessage="Datum snětí"
          />
        }
      />
      <FormSelect
        name="exportTemplate"
        label={
          <FormattedMessage
            id="ES__DISPATCHES__SEND_BY_NOTICE_BOARD__FIELD_LABEL__TEMPLATE"
            defaultMessage="Šablona"
          />
        }
        source={templates}
        tooltipMapper={(o) => o.name}
        valueIsId={true}
      />
    </>
  );
}
