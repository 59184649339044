import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  DictionaryEvidence,
  TableCells,
  TableColumn,
  TableFilterCells,
  useStaticListSource,
} from '@eas/common-web';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { DictProcessingType } from '@models';
import { Agenda } from '@enums';

export function useColumns(): TableColumn<DictProcessingType>[] {
  const intl = useIntl();
  const { agendas, processingTypeSubstantiveStructures } =
    useContext(StaticDataContext);

  const useAgendas = () => useStaticListSource(agendas);
  const useProcessingTypeSubstantiveStructures = () =>
    useStaticListSource(processingTypeSubstantiveStructures);

  const { columnName, columnActive, columnOrder } =
    DictionaryEvidence.useDictionaryColumns<DictProcessingType>();

  return [
    columnName,
    columnActive,
    columnOrder,
    {
      datakey: 'defaultForAgendas',
      filterkey: 'defaultForAgendas.id',
      name: intl.formatMessage({
        id: 'ES__DICT_PROCESSING_TYPES__TABLE__COLUMN__DEFAULT_FOR_AGENDAS',
        defaultMessage: 'Výchozí pro agendy',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: ({ value }) =>
        value
          ?.map(
            (defaultAgenda: Agenda) =>
              agendas.find((agenda) => agenda.id === defaultAgenda)?.name
          )
          .join(', '),
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(useAgendas),
      filterable: true,
    },
    {
      datakey: 'substantiveStructure',
      filterkey: 'substantiveStructure.id',
      sortkey: 'substantiveStructure.name',
      name: intl.formatMessage({
        id: 'ES__DICT_PROCESSING_TYPES__TABLE__COLUMN__SUBSTANTIVE_STRUCTURE',
        defaultMessage: 'Věcná struktura návrhu',
      }),
      width: 200,
      CellComponent: TableCells.TextCell,
      valueMapper: ({ value }) =>
        processingTypeSubstantiveStructures.find(
          (structure) => structure.id === value
        )?.name,
      FilterComponent: TableFilterCells.useFilterSelectCellFactory(
        useProcessingTypeSubstantiveStructures
      ),
      filterable: true,
      sortable: true,
    },
  ];
}
