import { useContext } from 'react';
import { useIntl } from 'react-intl';
import {
  ApiFilterOperation,
  TableCells,
  TableColumn,
  eqFilterParams,
  useStaticListSource,
} from '@eas/common-web';
import { FilterAutocompleteCell } from '@composite/filter/filter-cells/filter-autocomplete-cell';
import { FilterCheckboxCell } from '@composite/filter/filter-cells/filter-checkbox-cell';
import {
  FilterDateCell,
  FilterDateWithoutOperationCell,
} from '@composite/filter/filter-cells/filter-date-cell';
import { FilterDateTimeWithoutOperationCell } from '@composite/filter/filter-cells/filter-date-time-cell';
import { FilterNumberCell } from '@composite/filter/filter-cells/filter-number-cell';
import { FilterSelectCell } from '@composite/filter/filter-cells/filter-select-cell';
import { FilterTextCell } from '@composite/filter/filter-cells/filter-text-cell';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';
import { EsFile } from '@models';
import { Role } from '@enums';
import { useActTypes } from '../dict-act-type/dict-act-type-api';
import { useConcernedAuthorityTypes } from '../dict-concerned-authority-type/dict-concerned-authority-type-api';
import { useOpponents } from '../dict-opponent/dict-opponent-api';
import { useProceedingsSubjects } from '../dict-proceedings-subject/dict-proceedings-subject-api';
import { useProcessingTypes } from '../dict-processing-type/dict-processing-type-api';
import { useProposerDistinctions } from '../dict-proposer-distinction/dict-proposer-distinction-api';
import { useProposerTypes } from '../dict-proposer-type/dict-proposer-type-api';
import { useSenates } from '../dict-senate/dict-senate-api';
import { useSettlementMethods } from '../dict-settlement-method/dict-settlement-method-api';
import { useSubjectIndexes } from '../dict-subject-index/dict-subject-index-api';
import { useJudges } from '../judge/judge-api';
import { useUserRoles } from '../role/role-api';

export function useColumns(): TableColumn<EsFile>[] {
  const intl = useIntl();

  const {
    agendas,
    documentTypes,
    participantTypes,
    decisionForms,
    processingTypeSubstantiveStructures,
  } = useContext(StaticDataContext);
  const useDecisionForms = () => useStaticListSource(decisionForms);
  const useProcessingTypeSubstantiveStructures = () =>
    useStaticListSource(processingTypeSubstantiveStructures);
  const useAgendas = () => useStaticListSource(agendas);
  const useTypes = () => useStaticListSource(documentTypes);
  const useParticipantTypes = () => useStaticListSource(participantTypes);

  const useSolvers = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.SOLVER }));

  const useIndexers = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.INDEX }));

  const useSecretaries = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.SECRETARY }));

  const useAssistants = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.ASSISTANT }));

  const useAnalysts = () =>
    useUserRoles(eqFilterParams({ field: 'role.id', value: Role.ANALYST }));

  return [
    {
      datakey: 'agenda.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__AGENDA',
        defaultMessage: 'Agenda',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useAgendas),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 1,
    },
    {
      datakey: 'number.value',
      sortkey: 'number.valueSort',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__FILE_NUMBER',
        defaultMessage: 'Spisová značka',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 2,
    },
    {
      datakey: 'number.order',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ORDER',
        defaultMessage: 'Pořadí',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 4,
    },
    {
      datakey: 'number.year',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__YEAR',
        defaultMessage: 'Rok',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterNumberCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 5,
    },
    {
      datakey: 'deliveryDateFrom',
      filterkey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DELIVERY_DATE_FROM',
        defaultMessage: 'Datum doručení od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateWithoutOperationCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 6,
    },
    {
      datakey: 'deliveryDateTo',
      filterkey: 'deliveryDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DELIVERY_DATE_TO',
        defaultMessage: 'Datum doručení do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateWithoutOperationCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 7,
    },
    {
      datakey: 'incomingNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__INCOMING_NUMBER',
        defaultMessage: 'Došlé číslo',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 8,
    },
    {
      datakey: 'closingDateFrom',
      filterkey: 'closingDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__CLOSING_DATE_FROM',
        defaultMessage: 'Datum uzavření od',
      }),
      width: 0,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: FilterDateTimeWithoutOperationCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 9,
    },
    {
      datakey: 'closingDateTo',
      filterkey: 'closingDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__CLOSING_DATE_TO',
        defaultMessage: 'Datum uzavření do',
      }),
      width: 0,
      CellComponent: TableCells.DateTimeCell,
      FilterComponent: FilterDateTimeWithoutOperationCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 10,
    },
    {
      datakey: 'notes.text',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__NOTE',
        defaultMessage: 'Poznámky',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      sortable: true,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 11,
    },
    {
      datakey: 'participants.name',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_NAME',
        defaultMessage: 'Účastník',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 12,
    },
    {
      datakey: 'participants.type.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_TYPE',
        defaultMessage: 'Účastník - Typ',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useParticipantTypes),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 13,
    },
    {
      datakey: 'participants.contact.subject.firstName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_FIRST_NAME',
        defaultMessage: 'Účastník - Jméno',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 13,
    },
    {
      datakey: 'participants.contact.subject.lastName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_LAST_NAME',
        defaultMessage: 'Účastník - Přijímění',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 14,
    },
    {
      datakey: 'participants.contact.subject.companyName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_COMPANY_NAME',
        defaultMessage: 'Účastník - Firma',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 15,
    },
    {
      datakey: 'participants.contact.address.street',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_STREET',
        defaultMessage: 'Účastník - Ulice',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 16,
    },
    {
      datakey: 'participants.contact.address.descriptiveNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_DESCRIPTIVE_NUMBER',
        defaultMessage: 'Účastník - Číslo popisné',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: false,
      visible: false,
      filterGroup: 1,
      filterOrder: 17,
    },
    {
      datakey: 'participants.contact.address.orientationNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_ORIENTATION_NUMBER',
        defaultMessage: 'Účastník - Číslo orientační',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 18,
    },
    {
      datakey: 'participants.contact.address.city',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_CITY',
        defaultMessage: 'Účastník - Město',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 19,
    },
    {
      datakey: 'participants.contact.subject.function',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_FUNCTION',
        defaultMessage: 'Účastník - Funkce',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 20,
    },
    {
      datakey: 'participants.proposerType.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSER_TYPE',
        defaultMessage: 'Typ navrhovatele',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProposerTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 21,
    },
    {
      datakey: 'participants.proposerDistinction.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROPOSER_DISTINCTION',
        defaultMessage: 'Rozlišení navrhovatele',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProposerDistinctions,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 22,
    },
    {
      datakey: 'participants.contact.subject.otherIdentification',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PARTICIPANT_OTHER_ID',
        defaultMessage: 'Navrhovatel / pisatel - Jiná identifikace',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 23,
    },
    {
      datakey: 'documents.type.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DOCUMENT_TYPE',
        defaultMessage: 'Dokument - Typ dokumentu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useTypes),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 24,
    },
    {
      datakey: 'documents.dateFrom',
      filterkey: 'documents.date',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DOCUMENT_DATE_FROM',
        defaultMessage: 'Dokument - Datum od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 25,
    },
    {
      datakey: 'documents.dateTo',
      filterkey: 'documents.date',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DOCUMENT_DATE_TO',
        defaultMessage: 'Dokument - Datum do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 26,
    },
    {
      datakey: 'documents.summary',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DOCUMENT_SUMMARY',
        defaultMessage: 'Dokument - Stručný obsah',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 1,
      filterOrder: 27,
    },
    {
      datakey: 'processingType.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROCEEDING_TYPE',
        defaultMessage: 'Typ řízení',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProcessingTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 1,
    },
    {
      datakey: 'processingType.substantiveStructure.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROCEEDING_TYPE_SUBSTANTIVE_STRUCTURE',
        defaultMessage: 'Typ řízení - Věcná struktura návrhu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useProcessingTypeSubstantiveStructures),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 2,
    },
    {
      datakey: 'senate.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SENATE',
        defaultMessage: 'Typ senátu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSenates,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 3,
    },
    {
      datakey: 'challengedActs.actType.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ACT_TYPE',
        defaultMessage: 'Napadený akt - Druh aktu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useActTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 4,
    },
    {
      datakey: 'challengedActs.concernedAuthorityType.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__CONCERNED_AUTHORITY_TYPE',
        defaultMessage: 'Napadený akt - Typ dotčeného orgánu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useConcernedAuthorityTypes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 5,
    },
    {
      datakey: 'challengedActs.concernedAuthority.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__CONCERNED_AUTHORITY',
        defaultMessage: 'Napadený akt - Název dotčeného orgánu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useOpponents,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 6,
    },
    {
      datakey: 'challengedActs.externalFileNumber',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__EXTERNAL_FILE_NUMBER',
        defaultMessage: 'Napadený akt - spisová značka / číslo aktu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 7,
    },
    {
      datakey: 'challengedActs.actName',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ACT_NAME',
        defaultMessage: 'Napadený akt - název aktu',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 8,
    },
    {
      datakey: 'challengedActs.firstInstance',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__FIRST_INSTANCE',
        defaultMessage: 'Napadený akt - Prvoinstanční',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterCheckboxCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 9,
    },
    {
      datakey: 'judge.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__JUDGE',
        defaultMessage: 'Soudce zpravodaj',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useJudges,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 10,
    },
    {
      datakey: 'indexUserRole.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__INDEXER',
        defaultMessage: 'Vedoucí rejstříku',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useIndexers,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 11,
    },
    {
      datakey: 'secretaryUserRole.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SECRETARY',
        defaultMessage: 'Tajemnice',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSecretaries,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 12,
    },
    {
      datakey: 'assistantUserRole.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ASSISTANT',
        defaultMessage: 'Asistent',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useAssistants,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 13,
    },
    {
      datakey: 'analystUserRole.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ANALYST',
        defaultMessage: 'Analytik - indexace',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useAnalysts,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 14,
    },
    {
      datakey: 'opinionsSourced.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__OPINION_SOURCED',
        defaultMessage: 'Zdrojové spisové značky stanovisek',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 15,
    },
    {
      datakey: 'opinionsChallenged.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__OPINION_CHALLENGED',
        defaultMessage: 'Napadené spisové značky stanovisek',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 16,
    },
    {
      datakey: 'discussionDateFrom',
      filterkey: 'discussionDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DISCUSSION_DATE_FROM',
        defaultMessage: 'Datum projednání od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 17,
    },
    {
      datakey: 'discussionDateTo',
      filterkey: 'discussionDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DISCUSSION_DATE_TO',
        defaultMessage: 'Datum projednání do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 18,
    },
    {
      datakey: 'publicationDateFrom',
      filterkey: 'publicationDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PUBLICATION_DATE_FROM',
        defaultMessage: 'Datum vyhlášení od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 19,
    },
    {
      datakey: 'publicationDateTo',
      filterkey: 'publicationDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PUBLICATION_DATE_TO',
        defaultMessage: 'Datum vyhlášení do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 20,
    },
    {
      datakey: 'decisionForm.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__DECISION_FORM',
        defaultMessage: 'Forma rozhodnutí',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterSelectCell(useDecisionForms),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 21,
    },
    {
      datakey: 'settlementMethods.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SETTLEMENT_METHOD',
        defaultMessage: 'Způsob vyřízení',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSettlementMethods,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 22,
    },
    {
      datakey: 'proceedingsSubjects.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__PROCEEDINGS_SUBJECT',
        defaultMessage: 'Předmět řízení',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useProceedingsSubjects,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 23,
    },
    {
      datakey: 'subjectIndexes.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBJECT_INDEXES',
        defaultMessage: 'Věcný rejstřík',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSubjectIndexes,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 24,
    },
    {
      datakey: 'regulations.concatenated',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__REGULATIONS',
        defaultMessage: 'Předpisy',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterOperation: ApiFilterOperation.CONTAINS,
      filterable: true,
      visible: false,
      filterGroup: 2,
      filterOrder: 25,
    },
    {
      datakey: 'solverUserRoles.id',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SOLVERS',
        defaultMessage: 'Řešitelé',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterAutocompleteCell(
        useSolvers,
        autocompleteLabelMapper
      ),
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 1,
    },
    {
      datakey: 'settlementDateFrom',
      filterkey: 'settlementDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SETTLEMENT_DATE_FROM',
        defaultMessage: 'Datum vyřízení od',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.GTE,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 2,
    },
    {
      datakey: 'settlementDateTo',
      filterkey: 'settlementDate',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SETTLEMENT_DATE_TO',
        defaultMessage: 'Datum vyřízení do',
      }),
      width: 0,
      CellComponent: TableCells.DateCell,
      FilterComponent: FilterDateCell,
      filterOperation: ApiFilterOperation.LTE,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 3,
    },
    {
      datakey: 'submissionContent',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__SUBMISSION_CONTENT_SPR',
        defaultMessage: 'SPR - Obsah podání',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterTextCell,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 4,
    },
    {
      datakey: 'complaint',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__COMPLAINT',
        defaultMessage: 'INF - stížnost',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterCheckboxCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 5,
    },
    {
      datakey: 'administrativeDecision',
      name: intl.formatMessage({
        id: 'ES__ES_FILES__TABLE__COLUMN__ADMINISTRATIVE_DECISION',
        defaultMessage: 'INF - Správní rozhodnutí',
      }),
      width: 0,
      CellComponent: TableCells.TextCell,
      FilterComponent: FilterCheckboxCell,
      filterOperation: ApiFilterOperation.EQ,
      filterable: true,
      visible: false,
      filterGroup: 3,
      filterOrder: 6,
    },
  ];
}
