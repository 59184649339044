import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormAutocomplete,
  FormCheckbox,
  FormContext,
  FormPanel,
  FormSelect,
  FormTextField,
  useEventCallback,
  useStaticListSource,
} from '@eas/common-web';
import { useContactTypes } from '@modules/dict-contact-type/dict-contact-type-api';
import { useCountries } from '@modules/dict-country/dict-country-api';
import { StaticDataContext } from '@components/evidence/static-data-provider/static-data-provider';
import { CityField } from '@components/form/address/city-field';
import { ZipCodeField } from '@components/form/address/zip-code-field';
import { autocompleteLabelMapper } from '@components/form/misc/label-mappers';

export function ContactDialog() {
  const { dataBoxTypes } = useContext(StaticDataContext);
  const { getFieldValues, setFieldValue } = useContext(FormContext);

  const dataBoxTypeSource = useStaticListSource(dataBoxTypes);
  const contactTypes = useContactTypes();

  const handleDataBoxChange = useEventCallback(() => {
    const { dataBox } = getFieldValues();

    if (!dataBox?.type && !dataBox?.identifier) {
      setFieldValue('dataBox', undefined);
    }
  });

  return (
    <>
      <FormPanel
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__PANEL_TITLE__CONTACT"
            defaultMessage="Kontakt"
          />
        }
        expandable={false}
      >
        <FormSelect
          name="type"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TYPE"
              defaultMessage="Typ kontaktu"
            />
          }
          source={contactTypes}
          labelMapper={autocompleteLabelMapper}
          required
        />
        <FormTextField
          name="titleBefore"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_BEFORE"
              defaultMessage="Titul před"
            />
          }
        />
        <FormTextField
          name="firstName"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FIRST_NAME"
              defaultMessage="Jméno"
            />
          }
        />
        <FormTextField
          name="lastName"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LAST_NAME"
              defaultMessage="Příjmení"
            />
          }
        />
        <FormTextField
          name="titleAfter"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__TITLE_AFTER"
              defaultMessage="Titul za"
            />
          }
        />
        <FormTextField
          name="department"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DEPARTMENT"
              defaultMessage="Útvar"
            />
          }
        />
        <FormTextField
          name="email"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__EMAIL"
              defaultMessage="E-mail"
            />
          }
        />
        <FormSelect
          name="dataBox.type"
          source={dataBoxTypeSource}
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DATA_BOX_TYPE"
              defaultMessage="Typ datové schránky"
            />
          }
          notifyChange={handleDataBoxChange}
          valueIsId
        />
        <FormTextField
          name="dataBox.identifier"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DATA_BOX"
              defaultMessage="Číslo datové schránky"
            />
          }
          notifyChange={handleDataBoxChange}
        />
        <FormCheckbox
          name="acceptsLetterDataBoxMessage"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ACCEPTS_LETTER_DATA_BOX_MESSAGES"
              defaultMessage="Přijímá poštovní datové zprávy"
            />
          }
        />
        <FormTextField
          name="phone"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__PHONE"
              defaultMessage="Telefon"
            />
          }
        />
        <FormTextField
          name="otherIdentification"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__OTHER_IDENTIFICATION"
              defaultMessage="Jiná identifikace"
            />
          }
        />
        <FormTextField
          name="function"
          label={
            <FormattedMessage
              id="ES__SUBJECTS__DETAIL__FIELD_LABEL__FUNCTION"
              defaultMessage="Funkce"
            />
          }
        />
      </FormPanel>
      <AddressField prefix="address" />
    </>
  );
}

const AddressField = ({ prefix }: { prefix: string }) => {
  const countries = useCountries();

  return (
    <FormPanel
      label={
        <FormattedMessage
          id="ES__SUBJECTS__DETAIL__PANEL_TITLE__ADDRESS"
          defaultMessage="Adresa"
        />
      }
      expandable={false}
    >
      <FormTextField
        name={`${prefix}.street`}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__STREET"
            defaultMessage="Ulice"
          />
        }
      />
      <FormTextField
        name={`${prefix}.descriptiveNumber`}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__DESCRIPTIVE_NUMBER"
            defaultMessage="Číslo popisné"
          />
        }
      />
      <FormTextField
        name={`${prefix}.orientationNumber`}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__ORIENTATION_NUMBER"
            defaultMessage="Číslo orientační"
          />
        }
      />
      <ZipCodeField prefix={prefix} loadDetail />
      <CityField prefix={prefix} />
      <FormAutocomplete
        name={`${prefix}.country`}
        source={countries}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__COUNTRY"
            defaultMessage="Stát"
          />
        }
        labelMapper={autocompleteLabelMapper}
      />
      <FormTextField
        name={`${prefix}.locationCode`}
        label={
          <FormattedMessage
            id="ES__SUBJECTS__DETAIL__FIELD_LABEL__LOCATION_CODE"
            defaultMessage="Kód adresního místa"
          />
        }
      />
    </FormPanel>
  );
};
